 @font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.woff2") format("woff2");    
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
  [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: flaticon;
    font-size: 20px;
    font-style: normal;
  }
  
  .flaticon-email:before {
      content: "\f101";
  }
  .flaticon-phone-call:before {
      content: "\f102";
  }
  .flaticon-maps-and-flags:before {
      content: "\f103";
  }
  .flaticon-instagram:before {
      content: "\f104";
  }
  .flaticon-gallery:before {
      content: "\f105";
  }
  .flaticon-serving-dish:before {
      content: "\f106";
  }
  .flaticon-edit:before {
      content: "\f107";
  }
  .flaticon-left-arrow:before {
      content: "\f108";
  }
  .flaticon-wedding:before {
      content: "\f109";
  }
  .flaticon-cake:before {
      content: "\f10a";
  }
  .flaticon-wedding-rings:before {
      content: "\f10b";
  }
  .flaticon-play:before {
      content: "\f10c";
  }
  .flaticon-quotation:before {
      content: "\f10d";
  }
  .flaticon-dove:before {
      content: "\f10e";
  }
  .flaticon-calendar:before {
      content: "\f10f";
  }
  .flaticon-heart:before {
      content: "\f110";
  }
  .flaticon-pinterest:before {
      content: "\f111";
  }
  .flaticon-facebook-app-symbol:before {
      content: "\f112";
  }
  .flaticon-twitter:before {
      content: "\f113";
  }
  .flaticon-instagram-1:before {
      content: "\f114";
  }
  .flaticon-linkedin:before {
      content: "\f115";
  }
  .flaticon-youtube:before {
      content: "\f116";
  }
  .flaticon-search:before {
      content: "\f117";
  }
  .flaticon-shopping-cart:before {
      content: "\f118";
  }
  .flaticon-left-arrow-1:before {
      content: "\f119";
  }
  .flaticon-user:before {
      content: "\f11a";
  }
  .flaticon-comment-white-oval-bubble:before {
      content: "\f11b";
  }
  .flaticon-calendar-1:before {
      content: "\f11c";
  }
  .flaticon-right-arrow:before {
      content: "\f11d";
  }
  .flaticon-play-1:before {
      content: "\f11e";
  }
  .flaticon-left-quote:before {
      content: "\f11f";
  }
  .flaticon-right-arrow-1:before {
      content: "\f120";
  }
  .flaticon-left-arrow-2:before {
      content: "\f121";
  }
  .flaticon-next:before {
      content: "\f122";
  }
  
  
  