/*---------------------
    FOOTER CSS START
----------------------*/

.footer {
    background: #edf3f8;
    position: relative;    
    overflow: hidden;
}

.footer ul {
    list-style: none;
    padding-left: 0;
}

.footer p {
    color: #525252;
}

.footer li {
    color: #525252;
}

.footer .container {
    position: relative;
}

.footer .wpo-upper-footer {
    padding:50px 0px;
    position: relative;
    z-index: 1;
}

.footer .wpo-upper-footer:before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #cde0ef;
    content: "";
    width: 98%;
    height: 90%;
    z-index: -2;
}

.footer .widget-title {
    margin-bottom: 30px;
}

.footer .widget-title h3 {
    font-size: 20px;
    color: #002642;
    margin: 0;
    text-transform: uppercase;
    position: relative;    
    font-weight: 600;
}

.footer .about-widget .logo {
    max-width: 180px;
    position: relative;    
}

.footer .about-widget p {
    margin-bottom: 0.8em;
    line-height: 1.9em;
}

.footer .about-widget p:last-child {
    margin-bottom: 0;
}

.footer .about-widget ul {
    overflow: hidden;
    padding-top: 10px;
    padding-left: 0;
}

.footer .about-widget ul li {
    font-size: 22px;
    float: left;
}

.footer .about-widget ul li a {
    color: #002642;
    width: 36px;
    height: 36px;
    line-height: 36px;
    background: #fff;
    display: block;
    text-align: center;
    border-radius: 50%;
    font-size: 18px;
}
.footer .about-widget ul li a i:before {
    font-size: 15px;
}
.footer .about-widget ul li a:hover {
    background: #002642;
    color: #fff;
}

.footer .about-widget ul li+li {
    margin-left: 25px;
}

.footer .link-widget {
    overflow: hidden;
}

.footer .link-widget ul li {
    position: relative;
}

.footer .link-widget ul li a {
    color: #575757;
    font-size: 17px;
}

.footer .link-widget ul li a:hover {
    text-decoration: underline;
}

.footer .link-widget ul li+li {
    padding-top: 15px;
}

.footer .contact-ft {
    margin-top: 20px;    
}

.footer .contact-ft ul li {
    margin-bottom: 15px;
}

.footer .contact-ft ul li a{
    position: relative;
    padding-left: 30px;
    color: #5880a2;
    font-size: 16px;
    text-decoration:none;
    font-weight: 500;
}

.footer .contact-ft ul li i {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.footer .contact-ft ul li i:before {
    font-size: 18px;
    margin-right: 15px;
}

.footer .wpo-lower-footer {
    text-align: center;
    position: relative;
    background: #fff;
}

.footer .wpo-lower-footer .row {
    padding: 20px 0;
    position: relative;
}

.footer .wpo-lower-footer .copyright {
    display: inline-block;
    font-size: 15px;
    font-size: 0.9375rem;
    margin: 0;
    color: #5880a2;
}

.footer .wpo-lower-footer .copyright a {
    color: #5880a2;
    text-decoration: none;
}

.footer .instagram ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0;
}

.footer .instagram ul li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 5px;
    padding: 0px 3px;
}

.footer .instagram ul li img {
    width: 100%;
}
.footer .widget_media_gallery .gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px 10px;
}
.footer .widget_media_gallery figure{
    margin: 0;
    max-height:100px;
    min-height:100px;
    overflow: hidden;
}
.footer .widget_media_gallery figure img{
    width: 100%;
    min-width:100%;
    height:100%;
    min-height:100%;
    object-fit:cover;
    object-position: center center;
}
.footer .wpo-lower-footer {
    text-align: center;
    position: relative;
    background: #fff;
}
.footer .wpo-lower-footer {
    background-color: #86a0b6;
}
.footer .wpo-lower-footer p {
    font-size: 16px;
    margin-bottom: 0;
    color: #fff;
}
footer .wpo-lower-footer p a {
    color: #fff;
    text-decoration:none;
}

@media(max-width:1199px){
    .footer .widget_media_gallery figure{        
        max-height: 80px;
        min-height: 80px;
    }
}

@media(max-width:991px){
    .footer .widget_media_gallery figure{        
        max-height:120px;
        min-height:120px;
    }
}
@media(max-width:767px){
    .footer .widget-title{
        margin-bottom: 20px;
    }
}
/*---------------------
    FOOTER CSS END
----------------------*/