.customLetter{
    
}

.shape_1{
    z-index: -99;
    width: 60vw;
    padding: 0;
}

.letter-16{
    font-size: 4rem;
}

.letter-span{
    font-size: 10rem;
}

.button{
    border: solid #972626 2px;
    border-radius: 10px;
    width: 75%;
    margin: 0 auto;
    background-color: #972626;
    color: white;
    font-weight: bold;
}

.w-60{
    display: block !important;
    width: 40%;
    margin: 50px auto !important;
}

#story{    
    background-image: url('../images/textura1.png');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;         
}

#story2{    
    background-image: url('../images/newspapper.png');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;         
}

.write-song{
    font-family: 'WriteSong';
    color: black;
    font-size: 1.5rem;
}

.eyesome{
    font-family: 'Eyesome';
    color: black;
    font-size: 2.5rem !important;
}

.s-3{
    font-size: 3rem !important;
}

.interlineado{
    line-height: 30px;
}

.custom-confirm-button {
    background-color: #972626; /* Cambia el color de fondo */
    color: white; /* Cambia el color del texto */
    border: none; /* Elimina el borde, si es necesario */
}

.custom-confirm-button:hover {
    background-color: #831f20; /* Cambia el color al pasar el mouse, opcional */
}

.swal2-container{
    background-color: aqua;
}

.esquina-flor {
    background-image: url('../images/esquina.png');
    background-size: 60vw;
    background-repeat: no-repeat;
    background-position: bottom right; 
    
}


.custom-margin{
    margin-bottom: 20vh;
}