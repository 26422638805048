/*-----------------------------
    OUR LOVE STORY CSS START
------------------------------*/
.love_story{
    position: relative;
}
.love_story .story-timeline {
    position: relative;
}

.love_story .story-timeline:after {
    content: "";
    background:#edf3f8;
    width: 2px;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  .love_story .story-timeline .round-shape {
    background: #fff;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 0;
    border: 1px solid #738ea5;
    transform: translateX(-50%);
    border-radius: 50%;
    z-index: 1;
  }
  .love_story .story-timeline .round-shape:before {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 14px;
    height: 14px;
    content: "";
    background: #86a0b6;
    border-radius: 50%;
  }
  .love_story .story-timeline .img-holder {
    max-width: 330px;
    width:330px;
    height:330px;
    padding: 10px;
    border: 1px dashed #86a0b6;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    background: #fff;
    margin: 0 auto;
  }
  .love_story .story-timeline .img-holder:after {
    background: #fff;
    content: "";
    width: 40px;
    height: 350px;
    left: -100%;
    opacity: .2;
    position: absolute;
    top: 0;    
    -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);    
    z-index: 1;
  }
  .love_story .story-timeline .img-holder:hover:after {
    left: 100%;    
  }
  .love_story .story-timeline .img-holder:before {
    position: absolute;
    left: -140px;
    top: 195px;
    width: 90%;
    height: 90%;
    border: 1px dashed #738ea5;
    content: "";
    border-radius: 50%;
    z-index: -1;
    border-bottom: 0;
    border-right: 0;
    border-top: 0;
    transform: rotate(66deg);
  }
  .love_story .story-timeline .img-holder.left-site:before {
    position: absolute;
    left: auto;
    right: -140px;
    transform: rotate(102deg);
  }
  .love_story .story-timeline .img-holder > img {
    border-radius: 50%;
    width: 100%;
    height:100%;
    object-fit:cover;
    object-position:center center;
  }
  .love_story .story-timeline .img-holder .story-shape-img {
    position: absolute;
    bottom: -45px;
    left: 55px;
  }
  .love_story .story-timeline .img-holder .story-shape-img img {
    border-radius: 0;
  }
  .love_story .story-timeline > .row {
    position: relative;
  }
  .love_story .story-timeline > .row .horizontal-line {
    background: rgba(134, 160, 182, 0.1);
    width: 70%;
    height: 1px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  .love_story .story-timeline > .row:last-child .col {
    padding-top: 0 !important;
  }
  .love_story .story-timeline > .row + .row {
    margin-top: 50px;
    padding-top: 15px;
  }
  .love_story .story-timeline > .row > .col:first-child {
    padding: 0 45px 0 100px;
  }
  .love_story .story-timeline > .row > .col:last-child {
    padding: 0 100px 0 45px;
  }
  .love_story .story-timeline .story-text {
    padding-left: 50px;
  }
  .love_story .story-timeline .left-site .story-text {
    padding-left: 0px;
    padding-right: 50px;
  }
  .love_story .story-timeline .story-text.right-align-text {
    padding-left: 0;
    margin-right: 50px;
  }
  .love_story .story-timeline .text-holder {
    padding-top: 45px !important;
    position: relative;
  }
  .love_story .story-timeline .text-holder .heart {
    background-color:#edf3f8;
    width: 43px;
    height: 43px;
    line-height: 41px;
    text-align: center;
    position: absolute;
    left: -22px;
    top: -22px;
    border-radius: 50%;
    z-index: 1;
  }
  .love_story .story-timeline .text-holder .fi {
    color: #738ea5;
  }
  .love_story .story-timeline .right-heart .heart {
    left: auto;
    right: -22px;
  }
  .love_story .story-timeline h3 {
    font-size: 2.5rem !important;
    font-size: 1.4666666667rem;
    font-weight: 600;    
    margin: 0 0 0.5em;      
  }

  .love_story .story-timeline p.title2 {
    font-size: 2.5rem !important;
    font-size: 1.4666666667rem;
    font-weight: 500;    
    margin: 0 0 0.5em;      
  }
  .love_story .story-timeline .date {
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    display: block;
    margin-bottom: 15px;
    color: #86a0b6;
  }
  .love_story .story-timeline p {
    margin-bottom: 0;
  }
  .love_story .story-timeline img {
    display: inline-block;
  }
  @media (max-width: 1399px){
    .love_story .story-timeline .img-holder:before{
      left:-95px;
    }
    .love_story .story-timeline .img-holder.left-site:before{
      right: -95px;
    }
    .love_story .shape-1 .sticky-shape, 
    .love_story .shape-2 .sticky-shape{
      top: 0;
    }
    .love_story .shape-1 .sticky-shape img, 
    .love_story .shape-2 .sticky-shape img {
      width: 40%;
    }
  }
  @media (max-width: 1199px){
    .love_story .story-timeline .img-holder:before{
      left:-51px;
    }
    .love_story .story-timeline .img-holder.left-site:before{
      right: -51px;
    }
  }
  @media(max-width:991px){
    section.love_story {
      padding: 0;
    }
    .love_story .shape-1,
    .love_story .shape-2{
      width: 15%;
    }
    .love_story .shape-1 img,
    .love_story .shape-2 img{
      width: 100% !important;
    }
    .love_story .story-timeline .img-holder:before,
    .love_story .story-timeline .round-shape,
    .love_story .story-timeline:after{
      display: none;
    }
    .love_story .story-timeline .story-text.right-align-text{
      margin: 0;
      padding: 0;
    }
    .love_story .story-timeline .story-text{      
      padding: 15px 25px !important;
      text-align: center;
      margin-top: 15px !important;
    }
    .love_story .story-timeline > .row > .col:last-child,
    .love_story .story-timeline > .row > .col:first-child{
      padding: 0 15px;
    }
    .love_story .story-timeline .text-holder .heart{
      display: none;
    }
    .row.order_column > div:first-child {
      order: 1;
    }
    .love_story .story-timeline .text-holder{
      padding-top: 0 !important;
    }
    .love_story .story-timeline > .row + .row{
      margin-top: 30px !important;
    }
  }
  
  @media(max-width:575px){
    .love_story .story-timeline .img-holder{
      width:300px;
      height:300px;
    }
  }
  @media(max-width:360px){
    .love_story .story-timeline .img-holder{
      width:250px;
      height:250px;
    }
  }  
/*-----------------------------
    OUR LOVE STORY CSS END
------------------------------*/
.img-40{
  width: 40%;
  margin: 0 auto;
  display: block;
}