 .page_loader {
    width: 100%;
    height: 100%;
    background: var(--theme-color);
    color: #5c453f;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999 !important;
  }
  .loading-wrapper {    
    position: fixed;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);        
  }
  .loader-heart {
    position: absolute;
    opacity: 0;
    -webkit-animation: pulse2 1.8s linear infinite;
    -moz-animation: pulse2 1.8s linear infinite;
    -ms-animation: pulse2 1.8s linear infinite;
    animation: pulse2 1.8s linear infinite;
  }
  .loader-heart1 {
    top: 0;
    left: 166px;
  }
  .loader-heart2 {
    width: 30px;
    top: 38px;
    left: 130px;
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
  }
  .loader-heart3 {
    width: 20px;
    left: 173px;
    top: 78px;
    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s;
    -moz-animation-delay: 0.8s;
  }
  
  @keyframes pulse2 {
    0% {
      transform: translateY(5px) scale(0.8);
      opacity: 0;
    }
    30% {
        transform: scale(1) translateY(-10px);
        opacity: 0.8;
    }
    60% {
        transform: scale(0.8) translateY(-20px);
        opacity: 0;
    }
  }

  @media(max-width:575px){
    .loader-heart1,
    .loader-heart2,
    .loader-heart3 {      
      left: 0;
    }    
  }